import _getEnvironment from "./getEnvironment";
import * as _resolveUrl2 from "resolve-url";
var _resolveUrl = "default" in _resolveUrl2 ? _resolveUrl2.default : _resolveUrl2;
var exports = {};
const isBrowser = _getEnvironment("type") === "browser";
const resolveURL = isBrowser ? _resolveUrl : s => s; // eslint-disable-line

exports = options => {
  const opts = {
    ...options
  };
  ["corePath", "workerPath", "langPath"].forEach(key => {
    if (options[key]) {
      opts[key] = resolveURL(opts[key]);
    }
  });
  return opts;
};
export default exports;